<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div
        class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10"
      >
        <div class="grid grid-cols-1 gap-2 lg:grid-cols-3">
          <div
            class="bg-gradient-to-r shadow rounded-lg"
            style="background-color: #272827"
          >
            <div style="padding: 10px; margin-top: 5px">
              <div class="grid grid-cols-2 gap-4 lg:grid-cols-2">
                <div class="md-layout-item md-size-50 md-small-size-50">
                  <img
                    :src="require(`@/assets/img/logoCar/${carFrist.brand}.svg`)"
                  />
                </div>
                <div class="md-layout-item md-size-50 md-small-size-50">
                  <div style="display: flex; justify-content: flex-end">
                    <div style="margin-right: 1%">
                      <div style="display: flex">
                        <img
                          src="https://pngroyale.com/wp-content/uploads/2022/02/red-new-icon-png-logo-.png"
                          style="width: 60px"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              :src="require(`@/assets/img/car/${carFrist.img}`)"
              style="margin-left: -10px"
            />

            <div class="m-4 text-white">
              <h3 class="text-1xl font-bold">{{ carFrist.brand }}</h3>

              <h2
                class="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
              >
                {{ carFrist.model }}
              </h2>

              <br />

              <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                <div class="">
                  <center>
                    <p style="font-weight: 600; font-size: 14px">ระยะทาง</p>

                    <a
                      class="text-xl l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                    >
                      {{ carFrist.distance }} กม.
                    </a>
                  </center>
                </div>

                <div class="">
                  <center>
                    <p style="font-weight: 600; font-size: 14px">เวลาชาร์จ</p>

                    <a
                      class="text-xl l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                    >
                      {{ carFrist.speed_charge }} นาที
                    </a>
                  </center>
                </div>

                <div class="">
                  <center>
                    <p style="font-weight: 600; font-size: 16px">ราคา</p>
                    <a
                      class="text-xl l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                    >
                      2,000 / วัน
                    </a>
                  </center>
                </div>
              </div>

              <br />
              <div class="grid grid-cols-3 gap-1 lg:grid-cols-3">
                <div class=" ">
                  <center>
                    <p style="font-weight: 600; font-size: 14px">แรงม้า</p>

                    <a
                      class="text-xl l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                    >
                      {{ carFrist.horsepower }} hp
                    </a>
                  </center>
                </div>

                <div class="col-span-2" style="margin-left: 15px">
                  <p style="font-weight: 600; font-size: 14px">
                    ความจุแบตเตอรี่
                  </p>

                  <a
                    class="text-xl l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-700"
                  >
                    {{ carFrist.battery_capacity }} kWh
                  </a>
                </div>
              </div>
              <br />

              <h5
                class="font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-800"
                style="margin-left: 20px"
              >
                ประเภทหัวชาร์จ
              </h5>

              <div v-for="p in carFrist.power_charge" :key="p">
                <div style="margin: 5px" v-if="p == 'AC Type 1'">
                  <b class="grid grid grid-cols-3">
                    <div>
                      <center>
                        <img
                          style="
                            border: 2px solid green;
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          src="@/assets/img/connectors/1.svg"
                        />
                      </center>
                    </div>
                    <div class="col-span-2">
                      {{ p }}
                    </div>
                  </b>
                </div>
                <div style="margin: 5px" v-if="p == 'AC Type 2'">
                  <b class="grid grid grid-cols-3">
                    <div>
                      <center>
                        <img
                          style="
                            border: 2px solid green;
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          src="@/assets/img/connectors/2.svg"
                        />
                      </center>
                    </div>
                    <div class="col-span-2">
                      {{ p }}
                    </div>
                  </b>
                </div>

                <div style="margin: 5px" v-if="p == 'DC CSS2'">
                  <b class="grid grid grid-cols-3">
                    <div>
                      <center>
                        <img
                          style="
                            border: 2px solid green;
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          src="@/assets/img/connectors/CCS2.svg"
                        />
                      </center>
                    </div>
                    <div class="col-span-2">
                      {{ p }}
                    </div>
                  </b>
                </div>

                <div style="margin: 5px" v-if="p == 'DC CHAdeMO'">
                  <b class="grid grid grid-cols-3">
                    <div>
                      <center>
                        <img
                          style="
                            border: 2px solid green;
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          src="@/assets/img/connectors/Chademo.svg"
                        />
                      </center>
                    </div>
                    <div class="col-span-2">
                      {{ p }}
                    </div>
                  </b>
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: flex-end">
              <div style="margin-right: 1%">
                <div style="display: flex">
                  <router-link v-bind:to="'/model/' + carFrist.model">
                    <button
                      class="w-42 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-600 to-green-600"
                    >
                      จองรถ
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
            <br />
          </div>
          <div class="col-span-2">
            <div class="grid grid-cols-1 gap-4 lg:grid-cols-3">
              <div
                class="transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-90"
                style="margin-top: 10px"
                v-for="c in cars"
                :key="c"
              >
                <div
                  style="
                    background-color: white;
                    padding: 10px;
                    margin-top: 5px;
                    border: 2px solid green;
                    border-radius: 10px 10px 10px 10px;
                    height: 100%;
                  "
                >
                  <img :src="require(`@/assets/img/logoCar/${c.brand}.svg`)" />
                  <img
                    :src="require(`@/assets/img/car/${c.img}`)"
                    style="max-height: 250px"
                  />

                  <br />

                  <h2
                    style="font-weight: 700"
                    class="text-xl l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-900 to-green-500"
                  >
                    {{ c.model }}
                  </h2>

                  <br />

                  <div
                    class="grid grid-cols-2 gap-2 lg:grid-cols-2"
                    style="color: black"
                  >
                    <div class="">
                      <p style="font-size: 12px">
                        ระยะทาง <br />

                        <a
                          class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-900 to-green-500"
                        >
                          {{ c.distance }}
                        </a>
                        กม.
                      </p>
                    </div>

                    <div class="">
                      <p style="font-size: 12px">
                        ระยะเวลาชาร์จแบบเร็ว <br />
                        <a
                          class="text-sm l font-sans font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-900 to-green-500"
                        >
                          {{ c.speed_charge }}
                        </a>
                        นาที
                      </p>
                    </div>
                  </div>

                  <br />

                  <p style="color: black">ประเภทหัวชาร์จ</p>
                  <div class="flex flex-col justify-center mt-3">
                    <div class="flex">
                      <div v-for="p in c.power_charge" :key="p">
                        <img
                          style="
                            border: 2px solid green;
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          v-if="p == 'AC Type 1'"
                          src="@/assets/img/connectors/1.svg"
                        />

                        <img
                          style="
                            border: 2px solid green;
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          v-if="p == 'AC Type 2'"
                          src="@/assets/img/connectors/2.svg"
                        />

                        <img
                          style="
                            border: 2px solid green;
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          v-if="p == 'DC CSS2'"
                          src="@/assets/img/connectors/CCS2.svg"
                        />

                        <img
                          style="
                            border: 2px solid green;
                            border-radius: 50%;
                            padding: 2px;
                            margin-left: 2px;
                          "
                          v-if="p == 'DC CHAdeMO'"
                          src="@/assets/img/connectors/Chademo.svg"
                        />
                      </div>
                    </div>
                    <div style="display: flex; justify-content: flex-end">
                      <div style="margin-right: 1%">
                        <div style="display: flex">
                          <router-link v-bind:to="'/model/' + c._id">
                            <button
                              class="w-42 flex-none text-sm px-6 py-2 border rounded text-yellow-50 border-none bg-gradient-to-r from-blue-600 to-green-600"
                            >
                              จองรถ
                            </button>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <br />
          <br />
          <br />
          <br />
          <Stats />
        </div>
      </div>

      <div
        class="flex flex-col items-center justify-start flex-grow w-full text-yellow-50 p-3 pb-28"
      >
        <!-- <div
          class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
        >
          <div
            class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-gray-100"
          >
            <div class="flex flex-col items-center">
              <img src="@/assets/logo.png" alt="" class="h-20 -mt-9" />

              <img
                src="https://bc.fun/assets/banner-mobile.31cdb8de.png"
                alt=""
                class="sm:h-30 md:h-40"
              />
            </div>

            <h6
              class="-mt-1 text-center text-4xl font-sans font-bold lg:text-5xl xl:text-5xl tracking-tight text-yellow-400"
            >
              <span class="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#5dc90b42-5ed4-45a6-8e63-2d78ca9d3d95)"
                    width="52"
                    height="24"
                  ></rect>
                </svg>
              </span>

              Casino where everyone is treated like a VIP!
            </h6>
          </div>

          <div class="grid grid-cols-2 gap-6 lg:grid-cols-3">
            <div
              class="w-40 sm:w-60 bg-gradient-to-r shadow rounded-lg"
              style="
                background-image: url(https://wolfbet-static.storage.googleapis.com/img/homepage/offer/slots/bg.svg);
              "
            >
              <div class="flex justify-center">
                <img
                  class="p-4"
                  style="max-width: 85%"
                  src="https://wolfbet-static.storage.googleapis.com/img/homepage/offer/hilo/el.svg"
                />
              </div>
              <div class="ml-4 pb-1 mt-2">Bet Hilo Hash</div>
            </div>
            <div
              class="w-40 sm:w-60 bg-gradient-to-r shadow rounded-lg"
              style="
                background-image: url(https://wolfbet-static.storage.googleapis.com/img/homepage/offer/liveCasino/bg.svg);
              "
            >
              <div class="flex justify-center">
                <img
                  class="p-2"
                  style="max-width: 80%"
                  src="https://wolfbet-static.storage.googleapis.com/img/homepage/offer/liveCasino/el.svg"
                />
              </div>
              <div class="ml-4 pb-1">Bet Casino Hash</div>
            </div>
            <div
              class="w-40 sm:w-60 bg-gradient-to-r shadow rounded-lg"
              style="
                background-image: url(https://wolfbet-static.storage.googleapis.com/img/homepage/offer/limbo/bg.svg);
              "
            >
              <div class="flex justify-center">
                <img
                  class="p-2"
                  style="max-width: 75%"
                  src="https://wolfbet-static.storage.googleapis.com/img/homepage/offer/slots/el.svg"
                />
              </div>
              <div class="ml-4 pb-1">Bet Slot Hash</div>
            </div>
          </div>
        </div> -->

        <!-- <LiveTx /> -->

        <!-- <Roadmap /> -->
        <OurTeam />

        <footer>
          <StickFooter />
        </footer>
      </div>

      <div class="bg-gradient-to-r from-green-800 to-blue-900">
        <div
          class="flex flex-col justify-start flex-grow w-full text-yellow-50"
        >
          <div
            class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
          >
            <div class="relative flex py-5 items-center">
              <div class="flex-grow border-t border-gray-400"></div>
              <div class="flex-grow border-t border-gray-400"></div>
            </div>
            <div class="grid grid-cols-2 gap-6 lg:grid-cols-4">
              <div class="text-center w-40 sm:w-60 cursor-pointer">
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Affiliate
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Block
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  FAQ
                </p>
              </div>
              <div
                class="text-center w-40 sm:w-60 cursor-pointer text-gray-100 hover:text-purple-300"
              >
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Twitter
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Telegram
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Facebook
                </p>
              </div>
              <div
                class="text-center w-40 sm:w-60 cursor-pointer text-gray-100 hover:text-purple-300"
              >
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Terms
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Support
                </p>
                <p
                  class="text-sm font-medium tracking-widest text-gray-300 hover:text-purple-400 lg:text-base"
                >
                  Contract
                </p>
              </div>
              <div class="text-center w-40 sm:w-60 cursor-pointer">
                <p
                  class="text-sm font-medium tracking-widest text-gray-400 uppercase lg:text-base"
                >
                  English (US)
                </p>
              </div>
            </div>
            <div class="relative flex items-center">
              <div class="flex-grow border-t border-gray-400"></div>
              <span class="flex-shrink mx-4 text-gray-400">Supports</span>
              <div class="flex-grow border-t border-gray-400"></div>
            </div>

            <div class="flex flex-col items-center justify-center m-5">
              <div class="flex">
                <div class="grid grid-cols-2 gap-6 lg:grid-cols-5">
                  <div>
                    <img
                      class="h-10 mr-4 shadow"
                      src="https://wolfbet-static.storage.googleapis.com/img/currencies/bch.svg"
                    />
                  </div>

                  <div>
                    <img
                      class="h-10 mr-4 shadow"
                      src="https://wolfbet-static.storage.googleapis.com/img/currencies/bnb.svg"
                    />
                  </div>

                  <div>
                    <img
                      class="h-10 mr-4 shadow"
                      src="https://wolfbet-static.storage.googleapis.com/img/currencies/usdt.svg"
                    />
                  </div>

                  <div>
                    <img
                      class="h-10 mr-4 shadow"
                      src="https://wolfbet-static.storage.googleapis.com/img/currencies/usdc.svg"
                    />
                  </div>

                  <div>
                    <img
                      class="h-10 mr-4 shadow"
                      src="https://wolfbet-static.storage.googleapis.com/img/currencies/busd.svg"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="relative flex items-center">
              <div class="flex-grow border-t border-gray-400"></div>
              <div class="flex-grow border-t border-gray-400"></div>
            </div>

            <div class="flex flex-col items-center justify-center mt-3">
              <div class="flex">
                <img
                  class="h-20 mr-4 shadow"
                  src="https://voltz.io/img/VOLTZ1.90cf8b7b.png"
                />

                <img
                  class="h-20 mr-4 shadow"
                  src="https://www.365.stream/img/logo.64755377.svg"
                />

                <img class="h-20 mr-4 shadow" src="@/assets/logo.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import { mapGetters, mapActions } from "vuex";

import transactions from "./transactions_bet.vue";

import car from "@/api/car";

import { POOL_ADDRESS, VERSION } from "../../config";

export default {
  data() {
    return { version: VERSION, cars: [], carFrist: {} };
  },
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    ConnectWallet,
    StickFooter,
    FundSummary,
    transactions,
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },
  },
  created() {
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }

    car.getCar().then((res) => {
      this.cars = [];

      this.carFrist = res.data.car[0];

      for (let index = 1; index < 7; index++) {
        this.cars.push(res.data.car[index]);
      }
    });
  },
};
</script>
